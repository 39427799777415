import React, { useState, useContext, createContext } from "react"

export const SignUpContext = createContext([])
export const SignUpProvider = ({ children }) => {
  const [text, setText] = useState("")
  return (
    <SignUpContext.Provider value={[text, setText]}>
      {children}
    </SignUpContext.Provider>
  )
}

const useSignUpContext = () => {
  const context = useContext(SignUpContext)
  if (context === undefined) {
    // Let's yell at ourselves to make sure we use our Provider wrapper
    throw new Error(
      "Oooops, I'm guessing your forgot to use the Provider for this context"
    )
  }
  return context
}

export default useSignUpContext
