import React, { useEffect } from "react"
import useIsBrowser from "../hooks/useIsBrowser"
import ReactDOM from "react-dom"
import useKeyPress from "../hooks/useKeypress"

const PageModalPlan = ({ children, show, onClose }) => {
  const isBrowser = useIsBrowser()
  useEffect(() => {
    if (isBrowser && show) {
      document.documentElement.style.overflow = "hidden"
    }
    return () => {
      if (isBrowser && show) {
        document.documentElement.style.overflow = ""
      }
    }
  }, [isBrowser, show])
  useKeyPress("Escape", onClose)
  return (
    isBrowser &&
    ReactDOM.createPortal(
      <div
        style={{
          opacity: show ? 1 : 0,
          pointerEvents: show ? "" : "none",
          transform: show ? "scale(1)" : "scale(.95)",
          transition: ".2s",
          overflow: "auto",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          background: "var(--sparkle-drkblue)",
          height: "100%",
          width: "100%",
          zIndex: 100000,
          position: "fixed",
          left: 0,
          top: 0,
          color: "#fff",
        }}
      >
        {children}
      </div>,
      document.body
    )
  )
}

export default PageModalPlan
