import React, { useState } from "react"
import styled from "styled-components"
import {
  Border,
  Top,
  Name,
  SparkleHeader,
  Cost,
  PricingHeading,
  ButtonMonthYear,
  Plan,
  Description,
  Bottom,
  Button,
  List,
  Container,
  Option,
  PricingToggle,
  Wrapper,
} from "./PricingStyling"
import { segmentAction } from "../hooks/useSegmentTrack"
import { Link } from "gatsby"

const Toggle = styled.button``
const ToggleContainer = styled.div`
  width: 46px;
  height: 25px;
  padding: 2px;
  margin: 0 0.75rem;
  border-radius: 40px;
  background: #465b78;

  &.active div {
    transform: translateX(100%);
  }
`
const ToggleSwitch = styled.div`
  transition: transform 0.3s ease 0s;
  width: 21px;
  height: 100%;
  border-radius: 50px;
  background: #fff;
  transform: translateX(0%);
`

const plans = [
  {
    accentColor: "var(--sparkle-yellow)",
    name: "Site",
    monthly: 0,
    yearlyMonthlyCost: 0,
    description: "Share content, get paid for products and services.",
    cta: "Select",
    list: ["Share links + content", "Sell products and services"],
    transactionFeeMonthly: "2",
    transactionFeeYearly: "2",
  },
  {
    accentColor: "var(--sparkle-green)",
    name: "Scheduler",
    monthly: 29,
    yearlyMonthlyCost: 25,
    description: "Grow your business with bookings.",
    cta: "Select",
    listHeading: ["All ", <strong>Site</strong>, " features, and:"],
    list: ["Schedule appointments", "Encourage reviews"],
    transactionFeeMonthly: "1",
    transactionFeeYearly: "1",
  },
  {
    accentColor: "var(--sparkle-brightblue)",
    name: "Assistant",
    monthly: 49,
    yearlyMonthlyCost: 45,
    description: "Save time and increase revenues!",
    cta: "Select",
    listHeading: ["All ", <strong>Scheduler</strong>, " features, and:"],
    list: ["Automatically answer FAQs with AI automation", "Automated upsell"],
    transactionFeeMonthly: "0",
    transactionFeeYearly: "0",
  },
]

const notes = [
  "2% transaction fee on products + services sold via We Sparkle platform",
]

const Pricing = ({
  selected,
  onChange,
  noBtns,
  pricingModel,
  setPricingModel,
  monthly,
  setMonthly,
  noHeading,
  planName,
  showFeatureCallout,
}) => {
  return (
    <Wrapper>
      <PricingHeading>
        {!noHeading ? <h1>Pricing</h1> : <h2>{planName} Plan</h2>}
        <PricingToggle
          style={{
            margin: noHeading && "2rem 0",
          }}
        >
          <ButtonMonthYear
            color={"#fff"}
            className={!pricingModel ? "active" : ""}
          >
            Pay Monthly
          </ButtonMonthYear>
          <Toggle
            isToggled={!monthly}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              segmentAction.track("Homepage Pricing Cycle Change", {
                value: monthly ? "monthly" : "annual",
              })
              setMonthly(!monthly)
              setPricingModel(!pricingModel)
            }}
          >
            <ToggleContainer className={!monthly ? "active" : ""}>
              <ToggleSwitch />
            </ToggleContainer>
          </Toggle>
          <ButtonMonthYear className={pricingModel ? "active" : ""}>
            Pay Yearly
          </ButtonMonthYear>
        </PricingToggle>
      </PricingHeading>
      <Container>
        {plans.map(
          (
            {
              accentColor: color,
              name,
              monthly: mon,
              yearlyMonthlyCost: year,
              description,
              cta,
              listHeading,
              list,
              transactionFeeMonthly,
              transactionFeeYearly,
            },
            i
          ) => {
            const active = selected === i
            return (
              <Option
                color={color}
                key={i}
                className={active ? "active" : ""}
                onClick={() => {
                  segmentAction.track("Homepage Payment Change", {
                    value: name,
                  })
                  onChange(i)
                }}
              >
                <Border color={color} className={"pos-full"} />
                <Top>
                  <span
                    style={{
                      opacity: active ? 1 : 0,
                      position: "absolute",
                      right: 15,
                      top: 15,
                      background: color,
                      width: 30,
                      height: 30,
                      color: "var(--sparkle-drkblue)",
                      fontWeight: "bold",
                      lineHeight: "30px",
                      textAlign: "center",
                      borderRadius: "100%",
                      transition: ".2s",
                      transform: active ? "" : "translateY(15px)",
                    }}
                  >
                    ✓
                  </span>
                  <SparkleHeader color={color}>Sparkle</SparkleHeader>
                  <Name>{name}</Name>
                  <Cost>
                    ${!pricingModel ? mon : year}
                    <span>/mo</span>
                  </Cost>

                  {/* </div> */}
                  <Plan color={color}>Plan</Plan>
                  <Description color={color}>{description}</Description>
                </Top>
                <Bottom>
                  {!noBtns ? (
                    <Button bg={color}>{active ? "Selected" : cta}</Button>
                  ) : (
                    <div style={{ height: "2rem" }} />
                  )}
                  <List>
                    {listHeading && <p>{listHeading}</p>}
                    {list.map((item, j) => (
                      <li key={item + j}>
                        <span style={{ marginRight: 5, color }}>✓</span> {item}
                      </li>
                    ))}
                    <li key={`fee`}>
                      <span style={{ marginRight: 5, color }}>✓</span>{" "}
                      {!pricingModel
                        ? transactionFeeMonthly
                        : transactionFeeYearly}
                      % transaction fee (plus Stripe processing fee)
                    </li>
                  </List>
                </Bottom>
              </Option>
            )
          }
        )}
      </Container>
      {showFeatureCallout && (
        <Link
          to="/pricing#features"
          onClick={() => {
            segmentAction.track(`Homepage Pricing Page Click`)
          }}
          className="show-feature-list"
        >
          View Full Feature List →
        </Link>
      )}
    </Wrapper>
  )
}

export default Pricing
