import React, { useState, useRef } from "react"
import useSignUpCheckName from "./useSignUpCheckName"
import { onSignUpClickStart } from "./useSignUpClickStart"
import { useAuth0 } from "../hooks/useAuth0"
import styled, { keyframes } from "styled-components"
import { ButtonPrimary } from "./Buttons"
import SignUpIndicator from "./SignUpIndicator"
import useSignUpContext from "./useSignUpContext"
import useShowHideModalContext from "./useShowHideModalContext"
import IconLoaderCircle from "../icons/IconLoaderCircle"
import usePrevious from "../hooks/usePrevious"
import { segmentAction } from "../hooks/useSegmentTrack"

const shift = keyframes`
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(10px);
  }
`

const Container = styled.div`
  color: #222222;
  font-size: 1.7rem;
  display: flex;
  > label {
    display: flex;
    position: relative;
  }
  .notification {
    height: 100%;
    position: absolute;
    display: flex;
    right: 1.2rem;
    top: 50%;
    transform: translateY(-50%);
    span {
      margin: auto;
    }
  }
  .text {
    padding: 0.55em 1em;
    pointer-events: none;
    height: 100%;
    position: absolute;
    left: -0.75em;
    display: flex;
    span {
      font-weight: bold;
      margin: auto;
    }
  }
  input {
    margin-left: -0.75em;
    width: calc(100% + 0.75em);
    background: #efefef;
    border-radius: 1000px;
    font-size: inherit;
    padding: 0.55em 1em;
    padding-left: 9.25ch;
    padding-right: 2.25em;
  }
  @media screen and (max-width: 900px) {
    font-size: 1.2rem;
    display: block;
    .text {
      left: 0em;
    }
    input {
      margin-left: 0;
      width: 100%;
      display: block;
    }
    .notification {
      right: 0.5rem;
    }
  }
`

const ButtonArrowMovement = styled.span`
  font-family: "Tahoma";
  margin-left: 10px;
  /* @media screen and (max-width: 900px) {
    margin-left: 0px;
  } */
`
const Button = styled(ButtonPrimary)`
  margin-left: 0.5em;
  font-size: 0.75em;
  display: flex;
  align-items: center;
  :hover ${ButtonArrowMovement} {
    animation: 0.6s ease-in-out ${shift} infinite both alternate;
  }
  @media screen and (max-width: 900px) {
    font-size: 1.2rem;
    width: 100%;
    justify-content: center;
    margin-left: 0;
    padding-left: 0;
    margin-top: 0.5rem;
    padding-right: 0;
    :hover ${ButtonArrowMovement} {
      animation: none;
    }
  }
`

const SignUp = ({
  btnText,
  plan,
  monthly,
  autoFocus, // Pass in autofocus for just the first sign up... adds cursor
}) => {
  const [isFocused, setFocus] = useState(autoFocus)
  const prevFocus = usePrevious(isFocused)
  const [text, setText] = useSignUpContext()
  const [loadingPurchaseUrl, setLoadingPurchaseUrl] = useState(false)
  const timeoutRef = useRef(null)
  const [modal, setModal] = useShowHideModalContext()
  const { user } = useAuth0()
  const [error, success, typing, setTyping] = useSignUpCheckName({ text })

  // Is a request fetching
  const fetching = !typing && text && !error && !success
  return (
    <>
      <Container>
        <label style={{ position: "relative", flex: 1 }}>
          <span className={"text"}>
            <span>sprkl.es/</span>
          </span>
          <input
            autoFocus={autoFocus}
            type={"text"}
            onFocus={() => {
              setFocus(true)
            }}
            onBlur={() => setFocus(false)}
            placeholder={"yourname"}
            value={text}
            onChange={(e) => {
              if (isFocused && !prevFocus) {
                // Only want to track when first starting to type until focused again
                segmentAction.track("Homepage Url Type")
              }
              const val = e.target.value
              setText(val)
              if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
              }
              if (!typing) setTyping(true)
              timeoutRef.current = setTimeout(() => {
                clearTimeout(timeoutRef.current)
                setTyping(false)
              }, 500)
            }}
          />
          <span
            className={"notification"}
            onClick={() => {
              if (error) setText("") // clear out if we click on the "x" on error state
            }}
          >
            <SignUpIndicator
              error={error}
              success={success}
              loading={fetching}
            />
          </span>
        </label>
        {/* Do not disable btn - just only pass along url data and if no good have server select */}
        <Button
          onClick={() => {
            if (!modal) {
              // Open modal
              segmentAction.track("Homepage Url Modal Open")
              setModal(true)
            } else {
              // Start site!!! :)
              segmentAction.track("Homepage Url Modal New Account Click")
              onSignUpClickStart({
                text,
                success,
                plan,
                monthly,
                setLoadingPurchaseUrl,
                user,
              })
            }
          }}
          disabled={loadingPurchaseUrl}
        >
          <>
            <span>{btnText || "Start now "}</span>
            {loadingPurchaseUrl ? (
              <IconLoaderCircle
                style={{ marginLeft: 10 }}
                indicator={"#fff"}
                widthPercent={0.17}
                bg={"rgba(255,255,255,.5)"}
                length={20}
              />
            ) : (
              <ButtonArrowMovement> →</ButtonArrowMovement>
            )}
          </>
        </Button>
      </Container>
      <p style={{ marginTop: ".4rem" }}>
        {!error && !success && "\u00a0"}
        {error && (
          <span style={{ color: "var(--sparkle-red)" }}>
            Name already taken, please try again.
          </span>
        )}
        {success && "\u00a0"}
      </p>
    </>
  )
}

export default SignUp
