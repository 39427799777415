import styled from "styled-components"
import { ButtonBase } from "./Buttons"

export const Border = styled.div`
  border: 2px solid ${({ color }) => color};
  transition: 0.3s;
  opacity: 0.15;
  border-radius: 20px;
  position: absolute;
  left: 0;
  top: 0;
`
export const PricingHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    margin-bottom: 1.5rem;
    opacity: 1;
  }
`
export const PricingToggle = styled.div`
  display: flex;
  align-items: center;
`
export const Toggle = styled.button`
  position: relative;
  width: 60px;
  height: 25px;
  padding: 2px;
  margin: 0 0.75rem;
  border-radius: 40px;
  background: #465B78;
`

export const ButtonMonthYear = styled.div`
  padding: 0.4em 0.6em 0.3em;
  border-radius: 2em;
  color: #9DA7B7;
  font-size: 1rem;

  &.active {
    font-weight: bold;
    color: #fff;
  }
`

export const Button = styled(ButtonBase)`
  position: relative;
  transform: translateZ(0);
  width: 100%;
  opacity: 1;
  color: var(--sparkle-drkblue) !important;
  padding: 0.7em 0.5em;
  font-weight: bold;
  font-size: 1rem;
  background: ${({ bg }) => bg};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  border-radius: 100px;
  margin: 1.5rem 0rem 2rem;
  transition: 0.15s;
`

export const SparkleHeader = styled.h4`
  color: ${({ color }) => color};
  font-size: 1rem;
  opacity: 1;
  transition: 0.2s;
  @media screen and (max-width: 900px) {
    opacity: 1;
  }
`
export const Plan = styled(SparkleHeader)`
  ::before,
  :after {
    content: "";
    width: 15%;
    height: 1px;
    background: currentColor;
  }
  :before {
    margin-right: 5px;
  }
  :after {
    margin-left: 5px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 1rem 0 0.75rem;
  @media screen and (max-width: 900px) {
    display: none;
  }
`

export const Option = styled.article`
  position: relative;
  text-align: center;
  padding: 3rem 1.5rem 1rem;
  flex: 1 0 0;
  cursor: pointer;
  transform: translateZ(0);
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  :hover,
  &.active {
    transform: translateY(-5px);
    ${SparkleHeader},
    ${Plan} {
      opacity: 1;
    }
    ${Button} {
      opacity: 1;
      transform: scale(1.01) translateY(-1px) translateZ(0);
      box-shadow: 0 10px 15px rgba(0, 0, 0, 0.5);
      span {
        transform: translateY(-50%);
        opacity: 1;
      }
    }
    ${Border} {
      opacity: 1;
    }
  }
  @media screen and (max-width: 900px) {
    flex-direction: row;
    padding: 1.5rem;
  }
`
export const Description = styled.h5`
  font-weight: bold;
  letter-spacing: 0.02em;
  font-size: 1rem;
  @media screen and (max-width: 900px) {
    letter-spacing: 0.02em;
    margin-bottom: 0;
    color: ${({ color }) => color};
  }
`

export const List = styled.ul`
  text-align: left;
  font-size: 0.9rem;
  list-style: none;
  max-width: 200px;
  margin: 0 auto 1.5rem;

  li {
    display: flex;
    margin-bottom: 0.7em;
  }
  @media screen and (max-width: 900px) {
    order: 0;
    margin: 0;
  }
`

export const Cost = styled.p`
  margin: 0.75rem 0 0rem;
  margin-bottom: 0.5rem !important;
  font-size: 2.75rem;
  font-weight: bold;
  span {
    font-size: 1rem;
  }
  @media screen and (max-width: 900px) {
    font-size: 2rem;
  }
`

export const Name = styled.h3`
  font-size: 1.75rem;
  @media screen and (max-width: 900px) {
    letter-spacing: 0.02em;
    font-size: 1.3rem;
  }
`

// Or left container in mobile
export const Top = styled.div`

  @media screen and (max-width: 900px) {
    flex: 1 0 0;
    margin-right: 1.5rem;
    text-align: left;
  }
`

// Or right container in mobile
export const Bottom = styled.div`
  flex: 1 0 0;

  p {
    margin-bottom: 0.75rem;
    text-align: center;
  }

  @media screen and (max-width: 900px) {
    flex: 1.2 0 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;

    button {
      margin: 0px;
      opacity: 1;
      order: 1;
    }
    p {
    text-align: left;
    }
  }
`
export const Wrapper = styled.div`
  margin-bottom: 6rem;

  .show-feature-list {
    display: block;
    margin-top: 3rem;
    text-align: center;
    font-weight: bold;
    color: white;
  }
  @media screen and (max-width: 900px) {
    margin-bottom: 3rem;

    .show-feature-list {
      margin-top: 2rem;
    }
  }
`
export const Container = styled.div`
  display: flex;
  gap: 10px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`
